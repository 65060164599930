import { useEffect, useState } from "react";
import { useParams } from "react-router";

import { validateNewDevice } from "@api";
import { Loading } from "@atoms";
import { Message } from "@templates";
import { Container } from "@molecules";

export default function NewDevice() {
  const { token } = useParams();
  const [result, setResult] = useState(null);

  useEffect(() => {
    validateNewDevice({ token })
      .then((data) => {
        console.log("Response:", data);
        setResult(true);
      })
      .catch((error) => {
        console.log("Error:", error);
        setResult(false);
      });
  }, [token]);

  const renderMessage = () =>
    result ? (
      <Container>
        <Message>
          <Message.Emoji>🎉</Message.Emoji>
          <Message.Title>¡Listo!</Message.Title>
          <Message.Body>
            Ya podés volver a la app para seguir operando desde tu nuevo
            dispositivo.
          </Message.Body>
        </Message>
      </Container>
    ) : (
      <Container>
        <Message>
          <Message.Emoji>🤕</Message.Emoji>
          <Message.Title>
            <br />
            ¡Uy! El link ya no anda
          </Message.Title>
          <Message.Body>
            Volvé a la app para iniciar sesión nuevamente.
          </Message.Body>
        </Message>
      </Container>
    );

  return result === null ? <Loading /> : renderMessage();
}
