import { Colors, Typography } from '@styles';

const styles = {
  container: {
    display: 'flex',
    width: '100%',
    minHeight: 21,
  },
  textError: {
    color: Colors.errorDefault,
    ...Typography.errorDefault,
  },
};

export default styles;
