import React, { useRef } from 'react';
import PropTypes from 'prop-types';

import Fade from '../Fade';
import { useCombineStyles } from '@hooks';

import styles from './styles';

/**
 * Displays an error on the screen respecting the received props.
 * @param {object} props
 * @param {string} props.label
 * @param {object} props.customStyles
 * @param {string} props.fadeIn
 * @param {string} props.duration
 */
const FieldError = ({ label, customStyles, fadeIn = 'fadeIn', duration = '1s' }) => {
  const [styleComposer] = useCombineStyles();
  const refText = useRef('');
  if (typeof label === "string" && label.length > 0) {
    refText.current = label;
  } else {
    refText.current = "";
  }

  return (
    <Fade
      show={!!label}
      fadeInName={fadeIn}
      duration={duration}
      customStyles={styles.container}
    >
      <span style={styleComposer([styles.textError, customStyles])}>
        {refText.current}
      </span>
    </Fade>
  );
};

FieldError.propTypes = {
  label: PropTypes.string,
  customStyles: PropTypes.object,
  fadeIn: PropTypes.string,
  duration: PropTypes.string,
};

export default FieldError;
