const icons = Object.freeze({
  closeIcon: require("./svg/close.svg")?.default,
  eyeOff: require("./svg/eyeOff.svg")?.default,
  eye: require("./svg/eye.svg")?.default,
  arrowDown: require("./svg/arrowDown.svg")?.default,
  selected: require("./png/selected.png")?.default,
  unauthorizedPageIcon: require("./svg/unauthorizedPageIcon.svg")?.default,
  valid: require("./svg/valid.svg")?.default,
  invalid: require("./svg/invalid.svg")?.default,
});

export default icons;
