import useSubcomponentFinding from "@hooks/useSubcomponentFinding";
import styles from "./styles";

const Emoji = () => null;
const Title = () => null;
const Body = () => null;
const Footer = () => null;

function Message({ children }) {
  const renderFunc = useSubcomponentFinding(children);

  const emojiElement = renderFunc(Emoji, (child) => (
    <div style={styles.messageEmoji}>{child}</div>
  ));

  const titleElement = renderFunc(Title, (child) => (
    <p style={styles.messageTitle}>{child}</p>
  ));

  const bodyElement = renderFunc(Body, (child) => (
    <div style={styles.messageBody}>{child}</div>
  ));

  const footerElement = renderFunc(Footer, (child) => (
    <p style={styles.messageFooter}>{child}</p>
  ));

  return (
    <>
      {emojiElement}
      {titleElement}
      {bodyElement}
      {footerElement}
    </>
  );
}

Message.Emoji = Emoji;
Message.Title = Title;
Message.Body = Body;
Message.Footer = Footer;

export default Message;
