import React from "react";
import PropTypes from "prop-types";

import CustomIcon from "../CustomIcon";

import styles from "./styles";

/**
 * ![Image](https://bitbucket.org/tunubi/visual-references-web-mobile/raw/c6444643a2154940f9805d188b1e7638e01dde18/atoms/itemIcon.jpeg)
 * @param {string} text The label of the item
 * @param {boolean} isValid if it is true, it shows the left check icon colored / it shows the left check icon washed out .
 */
const ItemIcon = ({ text, isValid }) => {
  return (
    <p style={styles.container}>
      <CustomIcon
        customStyles={{ ...styles.icon, cursor: "none" }}
        icon={isValid ? "valid" : "invalid"}
      />
      <span style={styles.text}>{text}</span>
    </p>
  );
};

ItemIcon.propTypes = {
  text: PropTypes.string,
  isValid: PropTypes.bool,
  testIdentifiers: PropTypes.shape({
    iconContainer: PropTypes.string,
    text: PropTypes.string,
  }),
};

ItemIcon.defaultProps = {
  text: "",
  isValid: null,
  testIdentifiers: {},
};

export default ItemIcon;
