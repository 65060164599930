import axios from "axios";
import { onErrorRetryWithSecondaryBaseUrl } from "./interceptors";

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL_PRIMARY,
  timeout: 60000,
});

instance.interceptors.response.use(null, onErrorRetryWithSecondaryBaseUrl);

export default instance;
