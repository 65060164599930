import { Typography, Colors, Mixins } from '@styles';
import { FONT_SIZE_14 } from "@styles/typography";

const { scaleSize } = Mixins;

export const styles = {
  input: {
    width: "100%",
    height: "100%",
    border: "none",
    outline: "none",
    paddingBottom: scaleSize(10),
    borderBottomWidth: scaleSize(1),
    borderBottomStyle: "solid",
    borderRadius: scaleSize(0),
    borderColor: "lightgray",
    caretColor: Colors.primaryDefault,
    boxSizing: "border-box",
    WebkitTapHighlightColor: "transparent",
    ...Typography.bodyPrimary,
  },
  placeholder: {
    transition: "bottom .2s, font-size.2s",
    textAlign: "start",
    position: "absolute",
    pointerEvents: "none",
    bottom: scaleSize(10),
    color: Colors.baseLight,
    ...Typography.bodyPrimary,
  },
  placeholderDisplaced: {
    bottom: scaleSize(30),
    ...Typography.bodyPrimary,
    fontSize: FONT_SIZE_14,
  },
  inputFocused: {
    color: Colors.primaryDefault,
  },
  borderFocused: {
    borderBottomWidth: scaleSize(1),
    borderBottomStyle: "solid",
    borderColor: Colors.primaryDefault,
  },
  borderError: {
    borderBottomWidth: scaleSize(1),
    borderBottomStyle: "solid",
    borderColor: Colors.errorDefault,
  },
  error: {
    color: Colors.errorDefault,
  },
  container: {
    marginTop: scaleSize(40),
    position: "relative",
  },
  icon: {
    right: scaleSize(0),
    top: scaleSize(2),
    position: "absolute",
  },
};
