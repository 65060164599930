import { Container } from "@molecules";
import { Message } from "@templates";

export default function NotFound() {
  return (
    <Container>
      <Message>
        <Message.Emoji>🤷</Message.Emoji>
        <Message.Title>¡Uy! No encontramos esa página.</Message.Title>
        <Message.Body>
          ¿No estarás buscando{" "}
          <a
            href="https://www.tunubi.com/"
            target="_blank"
            title="Nubi | Una cuenta 100% digital y una tarjeta VISA para vos"
            rel="noreferrer"
          >
            esta página
          </a>{" "}
          🤔?
        </Message.Body>
      </Message>
    </Container>
  );
}
