import { Colors } from "@styles";
import { scaleSize } from "@styles/mixins";

const styles = {
  container: {
    display: "flex",
    flexDirection: "row",
    marginVertical: 3,
    alignItems: "center",
    textOverflow: "ellipsis",
  },
  iconContainer: {
    width: scaleSize(16),
    height: scaleSize(16),
    borderRadius: scaleSize(6),
    backgroundColor: Colors.primaryLighter,
    justifyContent: "center",
    alignItems: "center",
    marginRight: scaleSize(5),
  },
  iconContainerValid: {
    backgroundColor: Colors.primaryDefault,
  },
  text: {
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    marginLeft: scaleSize(10),
  },
  icon: {
    width: scaleSize(20),
    height: scaleSize(20),
  },
};

export default styles;
