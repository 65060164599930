import React, { useState } from "react";
import Lottie from "react-lottie";
import PropTypes from "prop-types";

import animation from "@animations";
import { useCombineStyles } from "@hooks";

import styles from "./styles";

/**
 * @param {object} props
 * @param {string} props.text
 * @param {string} props.outline
 * @param {string} props.disabled
 * @param {() => void | () => Promise<void>} props.onPress
 * @param {object} props.customStyle
 * @param {boolean} props.isLoading
 * @returns {React.ReactElement}
 */
const Button = ({
  text,
  outline,
  disabled,
  onPress,
  customStyle,
  isLoading,
}) => {
  const [isActive, setIsActive] = useState(false);
  const [styleComposer] = useCombineStyles();

  const stylesButtonDisabled =
    disabled && !isLoading && styles.buttonDefaultDisabled;
  const stylesButtonOutlineDisabled =
    disabled && outline && styles.buttonOutlineDisabled;
  const stylesButtonOutline = outline && styles.buttonOutline;

  const stylesTextDisabled = disabled && styles.btnText;
  const stylesTextOutlineDisabled =
    disabled && outline && styles.btnTextOutlineDisabled;
  const stylesTextOutline = outline && styles.btnTextOutline;
  const customButtonStyle = customStyle && customStyle.button;
  const customTextStyle = customStyle && customStyle.text;
  const activeButtonStyle = !isLoading && isActive && styles.activeButton;

  const defaultLottieOptions = {
    loop: true,
    autoplay: true,
    animationData: animation.primaryButton,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <button
      onClick={onPress}
      onTouchStart={() => setIsActive(true)}
      onTouchEnd={() => setIsActive(false)}
      disabled={disabled}
      style={styleComposer([
        styles.buttonDefault,
        stylesButtonDisabled,
        stylesButtonOutline,
        stylesButtonOutlineDisabled,
        customButtonStyle,
        activeButtonStyle,
      ])}
    >
      {isLoading ? (
        <Lottie options={defaultLottieOptions} height={48} width={48} />
      ) : (
        <span
          style={styleComposer([
            styles.btnText,
            stylesTextOutline,
            stylesTextDisabled,
            stylesTextOutlineDisabled,
            customTextStyle,
          ])}
        >
          {text}
        </span>
      )}
    </button>
  );
};

Button.propTypes = {
  text: PropTypes.string,
  outline: PropTypes.bool,
  disabled: PropTypes.bool,
  onPress: PropTypes.func,
  customTextStyle: PropTypes.shape({}),
  isLoading: PropTypes.bool,
  customStyle: PropTypes.shape({
    text: PropTypes.object,
    button: PropTypes.object,
  }),
};

export default Button;
