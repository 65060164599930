import { StrictMode } from "react";
import { render } from "react-dom";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { Layout, Home, NewDevice, NotFound, OpenInApp, RestorePassword } from "@pages";

import reportWebVitals from "./reportWebVitals";
import "./index.css";

render(
  <StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />

          <Route path="device/confirm/:token" element={<NewDevice />} />
          <Route path="password/reset/:email/:token" element={<RestorePassword />} />

          <Route
            path="/registration/web/registration/confirm/*"
            element={<OpenInApp />}
          />
        </Route>

        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
    <ToastContainer
      hideProgressBar
      position="bottom-center"
      style={{ paddingBottom: "2rem" }}
    />
  </StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
