import { Colors, Typography } from "@styles";
import { scaleSize } from "@styles/mixins";

const styles = {
  title: {
    textAlign: "center",
    ...Typography.bodyTitle,
    color: Colors.black,
  },
  checksContainer: {
    marginTop: scaleSize(40),
    marginBottom: scaleSize(24),
  },
  stepContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    width: "100%",
    height: "100%",
  },
};

export default styles;
