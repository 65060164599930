import Message from "@templates/Message";
import Container from "@molecules/Container";

export default function Home() {
  return (
    <Container>
      <Message>
        <Message.Emoji>🍪</Message.Emoji>
        <Message.Title>Epa, ¿cómo llegaste acá?</Message.Title>
        <Message.Body>
          Felicitamos tu curiosidad. Tomá una galleta:
          <br />
        </Message.Body>
      </Message>
    </Container>
  );
}
