import axios from "./instance";

export const validateNewDevice = ({ token }) =>
  axios.get(`/auth/web/device/confirm/${token}`);

  export const resetPassword = ({ token, newPassword }) =>
  axios.patch(
    "/auth/password/recover",
    { new_password: newPassword },
    { headers: { RecoveryToken: token } }
  );