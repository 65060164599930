import instance from "./instance";

export const onErrorRetryWithSecondaryBaseUrl = (error) =>
  new Promise((resolve, reject) => {
    const isRetry = error?.response?.config?._retry;

    if (!isRetry) {
      const config = {
        ...error.config,
        baseURL: process.env.REACT_APP_API_BASE_URL_SECONDARY,
      };
      config._retry = true;
      resolve(instance(config));
    } else {
      reject(error);
    }
  });
