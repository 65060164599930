const Styles = {
  messageEmoji: {
    fontSize: "6em",
  },
  messageTitle: {
    color: "#151C34",
    fontSize: "1.5rem",
    lineHeight: "1.5em",
    margin: "0.5em auto",
    fontWeight: "700",
    fontStyle: "normal",
  },
  messageBody: {
    fontFamily: "var(--main-font)",
    fontSize: "1 em",
    fontWeight: "400",
    lineHeight: "1.5em",
    textAlign: "center",
  },
  messageFooter: {
    color: "#747C94",
    fontSize: "1 em",
    fontWeight: "400",
  },
};
export default Styles;
