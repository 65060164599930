const Styles = {
  card: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignContent: "center",
    padding: "2rem",
    borderRadius: "16px",
    backgroundColor: "#fff",
    boxShadow: "0px 10px 32px rgba(46, 49, 65, 0.04)",
    maxWidth: "376px",
    minHeight: "490px",
  },
};
export default Styles;
