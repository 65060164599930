import { Mixins } from '@styles';

const styles = {
  icon: {
    width: Mixins.scaleSize(24),
    height: Mixins.scaleSize(24),
  },
};

export default styles;
