const Colors = Object.freeze({
  baseDefault: '#151c34',
  baseLight: '#747c94',
  baseLightest: '#ffffff',
  brandsPhoneCompanyClaro: '#ed1c24',
  brandsPhoneCompanyNextel: '#e05206',
  brandsPhoneCompanyMovistar: '#5cc500',
  brandsPhoneCompanyPersonal: '#00b0ea',
  brandsPhoneCompanyTuenti: '#191919',
  errorDefault: '#eb0038',
  errorLight: '#ff5a82',
  errorLighter: '#ffa9be',
  errorLightest: '#fff0f3',
  fadedRed: '#d32b41',
  jungleGreen: '#068036',
  lightRed: '#fceef0',
  lightGreen: '#ebf5ef',
  primaryDefault: '#0064FF',
  primaryLight: '#7CB1FF',
  primaryLighter: '#C9DEFF',
  primaryLightest: '#E8F1FF',
  primaryLightestPlus: '#f7faff',
  secondaryLight: '#70ffde',
  secondaryLighter: '#c2fff1',
  secondaryLightest: '#eef8f5',
  successDefault: '#00a378',
  secondaryDefault: '#2ddcb4',
  warningDefault: '#e03c00',
  warningLight: '#ff9752',
  warningLighter: '#ffc59e',
  warningLightest: '#fff3eb',
  warningDefaultYellow: '#b8860b',
  baseGray: '#747474',
  darkBlueDefault: '#151C34',
  backgroundLight: '#F2F2F2',
  black: '#000000',
  selectionBorder: '#DFE1FC',
  backdropDefault: 'rgba(21, 28, 52, 0.4)',
  frameBackground: 'rgba(255, 255, 255, 0.32)',
});

export default Colors;
