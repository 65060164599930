import { useState, useCallback } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

import { Input, Button, ItemIcon } from "@atoms";
import {
  lowerCaseRegex,
  upperCaseRegex,
  numericCaseRegex,
  specialCharactersRegex,
} from "@helpers/regex";
import { resetPassword } from "@api";
import { Message } from "@templates";
import { Container } from "@molecules";

import styles from "./styles";

export default function RestorePassword() {
  const { token } = useParams();

  const [newPassword, setNewPassword] = useState("");
  const [newConfirmPassword, setNewConfirmPassword] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");

  const [step, setStep] = useState(0);

  const longEnough = newPassword.length >= 8;
  const atLeastOneUppercase = upperCaseRegex.test(newPassword);
  const atLeastOneLetterAndNumber =
    (upperCaseRegex.test(newPassword) || lowerCaseRegex.test(newPassword)) &&
    numericCaseRegex.test(newPassword);
  const atLeastOneSpecialChar = specialCharactersRegex.test(newPassword);

  const passwordIsValid =
    longEnough &&
    atLeastOneUppercase &&
    atLeastOneLetterAndNumber &&
    atLeastOneSpecialChar;

  const confirmPasswordIsValid =
    passwordIsValid && newPassword === newConfirmPassword;

  const [loading, setLoading] = useState(false);

  const submitChange = useCallback(async () => {
    setLoading(true);
    try {
      await resetPassword({ token, newPassword });
      setStep(2);
    } catch (error) {
      toast(" ¡Uy, algo salió mal! Volvé a intentar.", {
        theme: "dark",
      });
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, [newPassword, token]);

  let stepElement;

  switch (step) {
    case 0:
      stepElement = (
        <div style={styles.stepContainer}>
          <div>
            <p style={styles.title}>Creá tu nueva contraseña</p>
            <Input
              key="newPasswordInput"
              label="Nueva contraseña"
              value={newPassword}
              onChangeText={setNewPassword}
              password
              hideError
            />
            <div style={styles.checksContainer}>
              <p>Tu contraseña debe tener al menos:</p>
              <ItemIcon isValid={longEnough} text={"8 carácteres"} />
              <ItemIcon isValid={atLeastOneUppercase} text={"Una mayúscula"} />
              <ItemIcon
                isValid={atLeastOneLetterAndNumber}
                text={"Una letra y un número"}
              />
              <ItemIcon
                isValid={atLeastOneSpecialChar}
                text={"Un caracter especial (Ej: $%&!)"}
              />
            </div>
          </div>
          <Button
            disabled={!passwordIsValid}
            text="Siguiente"
            onPress={() => setStep(1)}
          />
        </div>
      );
      break;
    case 1:
      stepElement = (
        <div style={styles.stepContainer}>
          <div>
            <p style={styles.title}>Confirmá el cambio de contraseña</p>
            <Input
              key="confirmPasswordInput"
              label="Nueva contraseña"
              value={newConfirmPassword}
              onChangeText={setNewConfirmPassword}
              onFocus={() => {
                setConfirmPasswordError("");
              }}
              onBlur={() => {
                if (!confirmPasswordIsValid) {
                  setConfirmPasswordError("Las contraseñas no coinciden");
                } else {
                  setConfirmPasswordError("");
                }
              }}
              password
              error={confirmPasswordError}
            />
          </div>

          <Button
            disabled={!confirmPasswordIsValid}
            text="Siguiente"
            isLoading={loading}
            onPress={submitChange}
          />
        </div>
      );
      break;
    case 2:
      stepElement = (
        <Message>
          <Message.Emoji>🎉</Message.Emoji>
          <Message.Title>¡Listo! Cambiaste tu contraseña</Message.Title>
          <Message.Body>
            Ya podés iniciar sesión con tu nueva contraseña desde la app.
          </Message.Body>
        </Message>
      );
      break;
    default:
      stepElement = null;
  }

  return <Container>{stepElement}</Container>;
}
