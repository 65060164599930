import Fonts from "./fonts";
import { scaleSize } from "./mixins";

// FONT SIZE
export const FONT_SIZE_48 = scaleSize(48);
export const FONT_SIZE_38 = scaleSize(38);
export const FONT_SIZE_32 = scaleSize(32);
export const FONT_SIZE_26 = scaleSize(26);
export const FONT_SIZE_24 = scaleSize(24);
export const FONT_SIZE_20 = scaleSize(20);
export const FONT_SIZE_16 = scaleSize(16);
export const FONT_SIZE_14 = scaleSize(14);
export const FONT_SIZE_12 = scaleSize(12);
export const FONT_SIZE_10 = scaleSize(10);

// LINE HEIGHT
export const LINE_HEIGHT_48 = scaleSize(48);
export const LINE_HEIGHT_32 = scaleSize(32);
export const LINE_HEIGHT_30 = scaleSize(30);
export const LINE_HEIGHT_24 = scaleSize(24);
export const LINE_HEIGHT_20 = scaleSize(20);
export const LINE_HEIGHT_18 = scaleSize(18);
export const LINE_HEIGHT_16 = scaleSize(16);
export const LINE_HEIGHT_14 = scaleSize(14);
export const LINE_HEIGHT_12 = scaleSize(12);
export const LINE_HEIGHT_8 = scaleSize(8);

const Typography = {
  bodyBottomTab: {
    fontFamily: Fonts.regular,
    fontSize: FONT_SIZE_10,
    lineHeight: LINE_HEIGHT_12,
    fontWeight: "800",
  },
  bodyPrimary: {
    fontFamily: Fonts.regular,
    fontSize: FONT_SIZE_16,
    lineHeight: LINE_HEIGHT_20,
  },
  bodyPrimaryStrong: {
    fontFamily: Fonts.regular,
    fontSize: FONT_SIZE_16,
  },
  bodyTitle: {
    fontFamily: Fonts.regular,
    fontSize: FONT_SIZE_24,
    lineHeight: LINE_HEIGHT_30,
    fontWeight: "700",
  },
  bodySubtitle: {
    fontFamily: Fonts.regular,
    fontSize: FONT_SIZE_20,
  },
  bodySmall: {
    fontFamily: Fonts.regular,
    fontSize: FONT_SIZE_14,
    lineHeight: LINE_HEIGHT_18,
  },
  bodySmallAction: {
    fontFamily: Fonts.regular,
    fontSize: FONT_SIZE_14,
    lineHeight: LINE_HEIGHT_14,
  },
  bodyFootnote: {
    fontFamily: Fonts.regular,
    fontSize: FONT_SIZE_10,
    lineHeight: LINE_HEIGHT_16,
  },
  buttonPrimary: {
    fontFamily: Fonts.regular,
    fontSize: FONT_SIZE_16,
  },
  singleLinePrimary: {
    fontFamily: Fonts.regular,
    fontSize: FONT_SIZE_16,
    lineHeight: LINE_HEIGHT_16,
  },
  singleLineRejected: {
    fontFamily: Fonts.regular,
    fontSize: FONT_SIZE_16,
    lineHeight: LINE_HEIGHT_16,
    textDecorationLine: "line-through",
  },
  singleLineSecondary: {
    fontFamily: Fonts.regular,
    fontSize: FONT_SIZE_12,
    lineHeight: LINE_HEIGHT_12,
  },
  requestCardAmount: {
    fontFamily: Fonts.regular,
    fontSize: FONT_SIZE_24,
    lineHeight: LINE_HEIGHT_24,
  },
  transactionCardAmount: {
    fontFamily: Fonts.regular,
    fontSize: FONT_SIZE_32,
    lineHeight: LINE_HEIGHT_32,
  },
  transactionCardAmountRejected: {
    fontFamily: Fonts.regular,
    fontSize: FONT_SIZE_32,
    lineHeight: LINE_HEIGHT_32,
    textDecorationLine: "line-through",
  },
  screenTitle: {
    fontFamily: Fonts.regular,
    fontSize: FONT_SIZE_24,
    lineHeight: LINE_HEIGHT_30,
  },
  lastMovementsCardEmptyTitle: {
    fontFamily: Fonts.regular,
    fontSize: FONT_SIZE_24,
  },
  lastMovementsCardTitle: {
    fontFamily: Fonts.regular,
    fontSize: FONT_SIZE_16,
    lineHeight: LINE_HEIGHT_20,
  },
  profileName: {
    fontFamily: Fonts.regular,
    fontSize: FONT_SIZE_24,
    lineHeight: LINE_HEIGHT_24,
  },
  headerTitle: {
    fontFamily: Fonts.regular,
    fontSize: FONT_SIZE_20,
    lineHeight: LINE_HEIGHT_20,
  },
  subtitleItem: {
    fontFamily: Fonts.regular,
    fontSize: FONT_SIZE_16,
    lineHeight: LINE_HEIGHT_16,
  },
  currencyInputTransactions: {
    fontFamily: Fonts.regular,
    fontSize: FONT_SIZE_48,
    lineHeight: FONT_SIZE_48,
  },
  errorDefault: {
    fontFamily: Fonts.regular,
    fontSize: FONT_SIZE_12,
    lineHeight: LINE_HEIGHT_12,
  },
  noSelect: {
    userSelect: "none",
    WebkitUserSelect: "none",
  },
};

export default Typography;
