import { Colors, Typography, Mixins } from '@styles';

const { scaleSize } = Mixins;

const styles = {
  buttonDefault: {
    width: '100%',
    height: 56,
    backgroundColor: Colors.primaryDefault,
    borderRadius: scaleSize(8),
    justifyContent: 'center',
    border: 'none',
    outline: 'none',
  },
  activeButton: {
    backgroundColor: Colors.primaryLight,
  },
  buttonDefaultDisabled: {
    width: '100%',
    height: 56,
    backgroundColor: Colors.primaryLight,
    borderRadius: scaleSize(8),
  },
  btnText: {
    textAlign: 'center',
    paddingLeft: scaleSize(11),
    paddingRight: scaleSize(11),
    paddingTop: scaleSize(4),
    color: Colors.primaryLightest,
    ...Typography.buttonPrimary,
    ...Typography.noSelect,
  },
  buttonOutline: {
    width: '100%',
    height: 56,
    backgroundColor: Colors.baseLightest,
    borderRadius: scaleSize(8),
    borderColor: Colors.primaryDefault,
    borderStyle: 'solid',
    borderWidth: scaleSize(1),
  },
  buttonOutlineDisabled: {
    width: '100%',
    height: 56,
    backgroundColor: Colors.baseLightest,
    borderRadius: scaleSize(8),
    borderColor: Colors.primaryLight,
    borderStyle: 'solid',
    borderWidth: scaleSize(1),
  },
  btnTextOutline: {
    color: Colors.primaryDefault,
  },
  btnTextOutlineDisabled: {
    color: Colors.primaryLight,
  },
};

export default styles;
