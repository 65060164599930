import React from "react";

import NubiCuentaLogo from "@icons/NCLogo.svg";
import { Card } from "@atoms";

import styles from "./styles";

/**
 * Wraps its content with logo and a card
 * @param {object} props
 * @param {React.ReactElement} props.children
 * @param {boolean} props.noCard If set `true`, the content will not be wrapped in a card
 * @param {boolean} props.noLogo If set `true`, the logo at the top of the page will not be displayed
 * @returns {React.ReactElement}
 */
 function Container({ children, noCard, noLogo = false }) {
  const showLogo = !noLogo;
  return (
    <div style={styles.container}>
      {showLogo && (
        <div style={styles.headerLogo}>
          <img src={NubiCuentaLogo} alt="nubiCuentaLogo" />
        </div>
      )}
      <div style={styles.cardContainer}>
        {noCard ? children : <Card>{children}</Card>}
      </div>
    </div>
  );
}

export default Container;
