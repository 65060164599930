import { useCallback } from "react";
import { Colors } from "@styles";

/**
 * Hook for managing styles
 */
const useCombineStyles = () => {
  const isDarkTheme = false;
  const getActualTheme = "light";
  const colors = Colors;

  /**
   * Convert strings in theme colors
   */
  const convertInColors = useCallback(
    (style = {}) => {
      let newStyle = {};
      for (let item in style) {
        if (colors[style[item]]) {
          newStyle[item] = colors[style[item]];
        }
      }
      return { ...style, ...newStyle };
    },
    [colors]
  );

  /**
   * Merge the styles
   */
  const styleComposer = (arrStyles = []) =>
    arrStyles.reduce((combinedStyles, style) => {
      try {
        return {
          ...combinedStyles,
          ...(typeof style === "function"
            ? { ...style(colors) }
            : { ...convertInColors(style) }),
        };
      } catch (err) {
        console.error("Error in styleComposer:", err);
        return {};
      }
    }, {});

  return [styleComposer, getActualTheme, isDarkTheme];
};

export default useCombineStyles;
