import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { useCombineStyles } from '@hooks';

import styles from './styles';

/**
 * Displays a component on screen respecting the received fade animation properties.
 * @param {object} props
 * @param {boolean} props.show According to the boolean value it determines whether to start the fade-in or fade-out animation
 * @param {string} props.duration Duration of the initial and final animation
 * @param {string} props.fadeInName Initial fade name
 * @param {string} props.fadeOutName Final fade name
 * @param {object} props.customStyles Custom text styles
 * @returns {React.ReactElement}
 */
const Fade = ({
  show = false,
  duration = '1s',
  fadeInName = 'fadeIn',
  fadeOutName = 'fadeOut',
  customStyles = {},
  children,
}) => {
  const [styleComposer] = useCombineStyles();
  const [toggle, toggleAnimation] = useState(show);
  const [isVisible, setVisible] = useState(toggle);

  useEffect(() => {
    toggleAnimation(show);
  }, [show]);

  useEffect(() => {
    if (toggle) setVisible(true);
  }, [toggle]);

  const fadeIn = `${fadeInName} ${duration}`;
  const fadeOut = `${fadeOutName} ${duration}`;

  const fadeProps = {
    style: { animation: toggle ? fadeIn : fadeOut },
    onAnimationEnd: () => !toggle && setVisible(false),
  };

  return (
    <div style={styleComposer([customStyles])}>
      {isVisible && (
        <div
          style={styleComposer([fadeProps.style, styles.container])}
          onAnimationEnd={fadeProps.onAnimationEnd}
        >
          {children}
        </div>
      )}
    </div>
  );
};

Fade.propTypes = {
  show: PropTypes.bool,
  duration: PropTypes.string,
  fadeInName: PropTypes.string,
  fadeOutName: PropTypes.string,
  customStyles: PropTypes.object,
  children: PropTypes.element,
};

export default Fade;
