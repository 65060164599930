import React, { useState } from 'react';
import PropTypes from 'prop-types';

import FieldError from '../FieldError';
import CustomIcon from '../CustomIcon';
import { useCombineStyles } from '@hooks';

import { styles } from './styles';

/**
 * @param {object} props
 * @param {string} props.key Key for the component
 * @param {string} props.htmlName The name for <input> and <label> html elements
 * @param {String} props.label The label of the input
 * @param {Function} props.onChangeText Function onchangetext
 * @param {boolean} props.password if it is true, it shows the icon to show / hide the password and it also set security text entry.
 * @param {string} props.error show error message and styles change
 * @param {boolean} props.email if true, it sets autocapitalize to 'none'
 * @param {number} props.maxLength indicates if the value must have a maximum of characters
 * @param {number} props.minLength indicates if the value must have a minimun of characters
 * @param {string} props.keyboardType indicates the type of the keyboard, if it is null, the default is set (the types are mapped to the React Native Input keyboard types, so the usage of this atom is the same as the mobile version)
 * @param {object} props.customStyles custom styles
 * @param {object} props.autoFocus if true, input will focus on render
 * @param {string} props.value input value
 * @param {Function} props.onFocus Action to execute when focusing input
 * @param {Function} props.onKeyPressed Action to execute when a key is pressed
 * @param {Function} props.onEndEditing Callback that is called when text input ends.
 * @param {Function} props.onBlur Function onBlur
 * @param {string} props.returnKeyType Determines how the return key should look
 * @param {Function} props.onSubmitEditing Callback that is called when the text input's submit button is pressed
 * @param {boolean} props.blurOnSubmit If true, the text field will blur when submitted. useful to avoid keyboard flickering when jumping between fields
 * @param {Function} props.getRef returns a reference to the component
 * @param {boolean} props.hideError Hides error component, removing the padding below the input
 */
const CustomInput = ({
  key,
  label,
  onChangeText,
  password,
  error,
  email,
  htmlName,
  value,
  keyboardType,
  autoFocus,
  customStyles,
  maxLength,
  minLength,
  onFocus,
  onBlur,
  onKeyPressed,
  onEndEditing,
  returnKeyType,
  onSubmitEditing,
  blurOnSubmit = true,
  getRef,
  hideError,
}) => {
  const [styleComposer] = useCombineStyles();

  const [isFocused, setIsFocused] = useState(false);
  const [hidePassword, setHidePassword] = useState(true);
  const [animateLabel, setAnimateLabel] = useState(value ? true : false);
  const iconEye = hidePassword ? "eyeOff" : "eye";
  const isFocusedStyle = isFocused && styles.inputFocused;
  const borderInputFocusedStyle = isFocused && styles.borderFocused;
  const errorStyle = error && styles.error;
  const borderInputErrorStyle = error && styles.borderError;
  const customInputStyle = customStyles && customStyles.input;
  const errorMessage =
    typeof error === "string" && error !== "" ? error : undefined;
  const placeHolderCustomStyle = customStyles?.placeholder;
  const placeHolderDisplacedStyle =
    (isFocused || animateLabel) && styles.placeholderDisplaced;
  const secureTextEntry = password && hidePassword;

  /**
   * @returns a valid input type attribute based on the equivalent React Native Input keyboard types
   */
  const getInputType = (type) => {
    switch (type) {
      case "email-address":
        return "email";
      case "phone-pad":
        return "tel";
      default:
        return "text";
    }
  };

  /**
   * @returns a valid input mode attribute based on the equivalent React Native Input keyboard types
   */
  const getInputMode = (type) => {
    switch (type) {
      case "number-pad":
        return "numeric";
      case "decimal-pad":
      case "numeric":
        return "decimal";
      default:
        return null;
    }
  };

  /**
   * execute corresponding callbacks when submitting the input by pressing 'enter' key
   */
  const onSubmit = (event) => {
    if (onSubmitEditing) onSubmitEditing(event);
    if (blurOnSubmit) event.target.blur();
  };

  return (
    <div>
      <div style={styleComposer([styles.container])}>
        <label
          key={`${key}Label`}
          style={styleComposer([
            styles.placeholder,
            placeHolderCustomStyle,
            placeHolderDisplacedStyle,
            errorStyle,
            isFocusedStyle,
          ])}
          htmlFor={key}
        >
          {label}
        </label>
        <input
          name={key}
          key={`${key}Input`}
          style={styleComposer([
            styles.input,
            customInputStyle,
            borderInputErrorStyle,
            borderInputFocusedStyle,
          ])}
          type={secureTextEntry ? "password" : getInputType(keyboardType)}
          inputMode={getInputMode(keyboardType)}
          onKeyDown={(event) => {
            if (onKeyPressed) onKeyPressed(event);
            if (event.key === "Enter") onSubmit(event);
          }}
          onChange={(event) => {
            setAnimateLabel(event.target.value === "" ? false : true);
            if (onChangeText) onChangeText(event.target.value);
          }}
          autoCapitalize={(password || email) && "none"}
          onFocus={(event) => {
            setIsFocused(true);
            if (onFocus) onFocus(event);
          }}
          autoFocus={autoFocus}
          onBlur={(event) => {
            setIsFocused(false);
            if (onBlur) onBlur(event);
            if (onEndEditing) onEndEditing(event);
          }}
          maxLength={maxLength}
          minLength={minLength}
          value={value}
          enterKeyHint={returnKeyType}
          ref={getRef}
        />
        {password && (
          <div style={styleComposer([styles.icon])}>
            <CustomIcon
              icon={iconEye}
              onClick={() => setHidePassword(!hidePassword)}
              disableDefaultEvent
            />
          </div>
        )}
      </div>
      {!hideError && <FieldError label={errorMessage} duration="0s" />}
    </div>
  );
};

CustomInput.propTypes = {
  label: PropTypes.string,
  onChangeText: PropTypes.func,
  password: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  email: PropTypes.bool,
  isFocused: PropTypes.bool,
  value: PropTypes.string,
  keyboardType: PropTypes.string,
  autoFocus: PropTypes.bool,
  onFocus: PropTypes.func,
  onKeyPressed: PropTypes.func,
  maxLength: PropTypes.number,
  minLength: PropTypes.number,
  customStyles: PropTypes.shape({
    input: PropTypes.object,
    placeholder: PropTypes.object,
  }),
  onBlur: PropTypes.func,
  onEndEditing: PropTypes.func,
  returnKeyType: PropTypes.string,
  onSubmitEditing: PropTypes.func,
  blurOnSubmit: PropTypes.bool,
  getRef: PropTypes.func,
  hideError: PropTypes.bool,
};

export default CustomInput;
