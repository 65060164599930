import PropTypes from "prop-types";

import icons from "@icons";
import { useCombineStyles } from "@hooks";

import styles from "./styles";

/**
 * Custom icon component
 * @param {object} props
 * @param {string} props.icon the name of the icon
 * @param {object} props.customStyles
 * @param {string} props.alt an alternate text for an image, if the image cannot be displayed
 * @param {() => void} props.onClick
 * @param {boolean} props.disableDefaultEvent cancels the onMouseDown event fired when clicking the img (useful to avoid losing focus on other elements, eg. inputs)
 * @returns
 */
const CustomIcon = ({
  icon,
  customStyles,
  alt,
  onClick,
  disableDefaultEvent,
}) => {
  const [styleComposer] = useCombineStyles();

  return (
    <img
      src={icons[icon]}
      style={styleComposer([styles.icon, customStyles])}
      alt={alt}
      onClick={onClick}
      onMouseDown={(event) => {
        if (disableDefaultEvent) event.preventDefault();
      }}
    />
  );
};

CustomIcon.propTypes = {
  icon: PropTypes.string,
  customStyles: PropTypes.object,
  alt: PropTypes.string,
  onClick: PropTypes.func,
  disableDefaultEvent: PropTypes.bool,
};

export default CustomIcon;
