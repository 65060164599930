const Styles = {
  headerLogo: {
    display: "flex",
    justifyContent: "center",
    paddingTop: "10px",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
  cardContainer: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    justifyContent: "center",
    alignContent: "center",
    padding: "24px",
    textAlign: "center",
  },
};
export default Styles;
