import Container from "@molecules/Container";
import Message from "@templates/Message";

export default function OpenInApp() {
  return (
    <Container>
      <Message>
        <Message.Emoji>📲</Message.Emoji>
        <Message.Title>¡Uy lo sentimos!</Message.Title>
        <Message.Body>
          Abrí el link desde el celular para continuar.
        </Message.Body>
      </Message>
    </Container>
  );
}
